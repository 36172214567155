import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
export default function usePropertyListOwner() {
  // Use toast
  const toast = useToast()
 

  const refPropertyListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'Image', sortable: true },
    { key: 'invoiceStatus',label: 'Property', sortable: true },
    { key: 'client', sortable: true },
    { key: 'total', sortable: true, formatter: val => `$${val}` },
    { key: 'issuedDate', sortable: true },
    { key: 'balance', sortable: true },
    { key: 'actions' },
  ]
  const tableColumns_property = [
    { key: 'image', label: 'Image', sortable: false , tdClass:'width-300 p-1'},
    { 
      key: 'property',
      label: 'Property', 
      sortable: true ,
      filterOptions: {
        enabled: true,
        placeholder: 'Search Name',
      },
    },
    { key: 'owner',label: 'Owner', sortable: true },
    { key: 'unit',label: 'House No Unit', sortable: true },
    { key: 'floors',label: 'Floor', sortable: true },
    { key: 'type_name',label: 'Type', sortable: true },
    { key: 'buildiing',label: 'Building', sortable: true },
    { key: 'bedroom',label: 'Br', sortable: true },
    { key: 'sqm',label: 'Sqm', sortable: true },
    { key: 'rent_price',label: 'Rent', sortable: true },
    { key: 'sale_price',label: 'Sale', sortable: true },
    { key: 'actions' },
    
  
  ]
  const perPage = ref(25)
  const totalProperty = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(null)
  const unit = ref(null)
  const floor = ref(null)
  const type = ref(null)
  const building = ref(null)
  const bedroom =ref(null)
  const sqm = ref(null)
  const price_rent = ref(null)
  const price_sale = ref(null)
 const statusId = ref(null)

  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const propertyfilter = ref(null)
  const ownerfilter = ref(null)
  const client_id = ref(router.currentRoute.params.id)
 
  const dataMeta = computed(() => {
    const localItemsCount = refPropertyListTable.value ? refPropertyListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProperty.value,
    }
  })

  const refetchData = () => {
    
    refPropertyListTable.value.refresh()
  }
  const saveSearch=()=>{
    

  }
  
  const deleteData = (id) => {
    //fetchDeleteProperty
    store.dispatch('app-property/fetchDeleteProperty',id).then(response=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Delete Success",
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      refetchData();
    }).catch(()=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Error Delete Property",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })

    })
    //console.log('id', id);
    
  }
  const actions = [];
  watch([currentPage, perPage, searchQuery, statusFilter, unit,floor, type, building, bedroom,sqm,price_rent,price_sale,propertyfilter,ownerfilter,statusId], () => {

    let filter = {
      searchQuery:searchQuery.value,
      statusFilter:statusFilter.value,
      unit:unit.value,
      floor:floor.value,
      type_name:type.value,
      building:building.value,
      bedroom:bedroom.value,
      sqm:sqm.value,
      price_sale:price_sale.value,
      propertyfilter:propertyfilter.value,
      ownerfilter:ownerfilter.value,
      statusId:statusId.value
    }
    //store.commit('propertyConfig/FILTER_PROPERTY',filter)
    refetchData()
  })
  const clearData = () => {
    
    store.commit('propertyConfig/FILTER_PROPERTY_CLEAR');
    searchQuery.value = ''
    unit.value = '';
    statusId.value = '';
    floor.value = '';
    type.value = '';
    building.value = '';
    bedroom.value = '';
    sqm.value = '';
    price_sale.value = '';
    propertyfilter.value = '';
    ownerfilter.value = '';
    statusId.value = '';
    
    refetchData()
  }
  const fetchTemplates = (filter)=>{
    console.log('filter',filter);
   
    searchQuery.value = filter.keyword;
    type.value = filter.type_name;
    propertyfilter.value = filter.propertyfilter;
    ownerfilter.value = filter.ownerfilter;
    floor.value = filter.floors;
    building.value = filter.building;
    bedroom.value = filter.bedroom;
    unit.value = filter.unit;
    sqm.value = filter.sqm;
    statusId.value = filter.status_id;
    refetchData();
   

  }
  const fetchProperties = (ctx, callback)=>{
   
    const offset = perPage.value*(currentPage.value-1);
    console.log('client_id',client_id);
    store
    .dispatch('app-property/fetchProperties', {
      perpage: perPage.value,
      offset:offset,
      bedroom:bedroom.value,
      type_name:type.value,
      unit:unit.value,
      sqm:sqm.value,
      statusId:statusId.value==="1"?statusId.value:null,
      keyword:searchQuery.value,
      propertyfilter:propertyfilter.value,
      ownerfilter:ownerfilter.value,
      floors:floor.value,
      building:building.value,
      action_id:statusFilter.value?statusFilter.value['id']:null,
      client_id:client_id.value?client_id.value:null
     
    
    })
    .then(response => {
    
    
     const properties =  response.data.total > 0 ? response.data.data:null;
     //const  properties = response.data.data;
     // const properties = response.data.data;
     // console.log('response',response);
      console.log('properties',properties);
      totalProperty.value = response.data.total
    //  const { invoices, total } = response.data
    callback(properties)
     // callback(invoices)
    
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: "Error fetching properties' list",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
    
  }
  store
    .dispatch('app-property/fetchgetActions', {})
    .then(response => {
      const action = response.data.data;
      console.log('reffetchgetActions',action);
      actions.value =action;
      
     
     
     
    })
    .catch(() => {
    
    })

  const fetchInvoices = (ctx, callback) => {
    console.log('a',callback);
    
    store
      .dispatch('app-property/fetchInvoices', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        console.log('refPropertyListTable',response);
        const { invoices, total } = response.data

        callback(invoices)
        totalInvoices.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceStatusVariantAndIcon = status => {
    if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' }
    if (status === 'Paid') return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'Downloaded') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
    if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
    if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
    if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const resolveClientAvatarVariant = status => {
    if (status === 'Partial Payment') return 'primary'
    if (status === 'Paid') return 'danger'
    if (status === 'Downloaded') return 'secondary'
    if (status === 'Draft') return 'warning'
    if (status === 'Sent') return 'info'
    if (status === 'Past Due') return 'success'
    return 'primary'
  }
  const updateData = (update)=>{
    console.log('update',update);
    if(update.avaliable_date){
      let avldate = update.avaliable_date.split('T');
      update.avaliable_date =  avldate[0];
    }
    if(update.expire_date){
      let expire_date = update.expire_date.split('T');
      update.expire_date =  expire_date[0];
    }
    
    store.dispatch('app-property/fetchUpdateProperty',update).then(response=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Update Success",
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      refetchData();
    }).catch((err)=>{
      console.log('err',err);

      toast({
        component: ToastificationContent,
        props: {
          title: "Error Update Property",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })

    })
   /* let data = JSON.stringify(update);
    axios.post('/property/v1/update_propertydate',data).then(response=>{
      if(response.data.success){
          this.$toast({
              component: ToastificationContent,
                props: {
                  title: 'Done!',
                  icon: 'BellIcon',
                },
            })

        }else{
          this.$toast({
              component: ToastificationContent,
                props: {
                  title: 'Error!',
                  icon: 'BellIcon',
                },
            })

        }
        refetchData();
    }).catch(()=>{
         this.$toast({
          component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'BellIcon',
            },
        })

    });*/

  }

  return {
    fetchInvoices,
    fetchProperties,
    tableColumns,
    tableColumns_property,
    perPage,
    currentPage,
    totalProperty,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPropertyListTable,
    unit,
    floor,
    type,
    building,
    bedroom,
    sqm,
    statusId,
    price_rent,
    price_sale,
    statusFilter,
    propertyfilter,
    ownerfilter,
    deleteData,
    resolveInvoiceStatusVariantAndIcon,
    resolveClientAvatarVariant,
    actions,
    clearData,
    saveSearch,
    fetchTemplates,
    refetchData,
    updateData
  }
}
