<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
           <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="actions_a"
              label="name"
              class="invoice-filter-select mr-1"
              placeholder="Select Status"
            >
              
            </v-select>
          
         
          <b-button
            variant="primary"
            class="mr-1"
            :to="{ name: 'property-owner-create', params: { ownerId: ownerId }}"
          >
          
            <feather-icon icon="PlusIcon" />
            <feather-icon icon="HomeIcon" />
            
            
          </b-button>
          <b-form-checkbox
            v-model="statusId"
            value="1"
            class="custom-control-primary mr-2"
          >
            Avl
          </b-form-checkbox>
          
         
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <div class="d-flex align-items-center justify-content-end">
           
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
             
              placeholder="Search..."
              style="max-width:200px;"
              
            />
       
          

           
          </div>
        </b-col>

       
      </b-row>
      

    </div>
      <b-table
      ref="refPropertyListTable"
      :items="fetchProperties"
      responsive
      striped
      :fields="tableColumns_property"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
    
   
      <template #cell(image)="data">
        <b-link variant="flat-primary" class="pb0" :to="{ name: 'property-upload', params: { id: data.item.id } }">
        <b-img   class="img_property" v-if="data.item.property_images[0]"
                    :src="data.item.property_images[0].fullfilepath"
                    fluid
                />
        <b-img   class="img_property" v-else
                    :src="'http://128.199.95.64:81/assets/images/default/property/picture.png'"
                    fluid
                />
        </b-link>
        
      </template>
      <template #cell(unit)="data">
        {{ data.item.units }} 
        {{ data.item.address_no }}
      </template>
      <template #cell(property)="data">
        <div class="list-property">
          <router-link :to="{ name: 'property-edit', params: { id: data.item.id } }" >{{ data.item.property_code }}</router-link>
           <b-badge v-if="data.item.action_id == 1" variant="light-secondary">
            {{ data.item.action_name }}
          </b-badge>
          <b-badge v-if="data.item.action_id == 2" variant="light-info">
            {{ data.item.action_name }}
          </b-badge>
           <b-badge v-if="data.item.action_id == 3" variant="light-success">
            {{ data.item.action_name }}
          </b-badge>
          <b-badge v-if="data.item.action_id == 4" variant="light-warning">
            {{ data.item.action_name }}
          </b-badge>
          <b-badge v-if="data.item.status_id == 1" variant="light-success">
            {{ data.item.status_code }}
          </b-badge>
          <b-badge v-else variant="light-secondary">
            {{ data.item.status_code }}
          </b-badge>
          <b-badge  variant="light-primary"   @click="doCopy(data.item.place_name,data.item.property_code)">
               <feather-icon
                icon="CopyIcon"
                size="24"
              />
             
          </b-badge>
           
          <br />
          {{ data.item.place_name }}<br />
          <p v-if="data.item.comments[0]" class="text-truncate mb-0 text-warning width-200" >{{ filterTags(data.item.comments[0].comment) }}</p>
           <b-badge variant="light-secondary" @click="formcomment_show(data.item.id)" >
            comment
          </b-badge>
    

        </div>
        
        
      </template>
      
      <template #cell(rent_price)="data">
        {{ data.item.rent_price | getcomma }}
       
      </template>
      <template #cell(sale_price)="data">
        {{ data.item.sale_price | getcomma }}
        
      </template>
      
      <template #cell(owner)="row">
        <b-form-checkbox
          v-model="row.detailsShowing"
          plain
          class="vs-checkbox-con"
          @change="row.toggleDetails"
        >
          
          <span class="vs-label">{{ row.item.owner_firstname  }} {{ row.item.owner_lastname  }}</span>
        </b-form-checkbox>
      </template>
      
      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col
              md="6"
              class="mb-1"
            >
            <b-row>
              <b-col md="6"  class="mb-1">
                Updated Date
                  <b-form-datepicker
                          
                  v-model="row.item.availble_date"
                          reset-button
            close-button
                          class="mb-1"
                        
                      
                          />
              </b-col>
             
              <b-col md="6"  class="mb-1">
                Expire date
                  <b-form-datepicker
                          
                  v-model="row.item.expire_date"
                          reset-button
            close-button
                          class="mb-1"
                        
                      
                          />
              </b-col>
             
              <b-col md="6"  class="mb-1">
                Sale Price
                <b-form-input v-model="row.item.sale_price"   />
                         
              </b-col>
              <b-col md="6"  class="mb-1">
                Rent Price
                <b-form-input v-model="row.item.rent_price"   />
                         
              </b-col>

              <b-col md="12"  class="mb-1">
                Status
                <v-select
                    v-model="row.item.status_id"
                    :reduce="(option) => option.id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="statuses"
                  />
                         
              </b-col>
            <b-col md="12">
              <b-button
          variant="primary"
          type="submit"
            @click="updateDate(row.item.id,row.item.availble_date,row.item.status_id,row.item.sale_price,row.item.rent_price,row.item.sqm,row.item.expire_date)"
        >
          Update
        </b-button>

            </b-col>
            </b-row>
            
                    
        
            
            
             
            </b-col>
           
            <b-col
              md="3"
              class="mb-1"
            >
            <strong>Full Name : </strong> <router-link :to="{ name: 'clients-edit', params: { id: row.item.owner_id } }"  target="_blank">{{ row.item.owner_firstname  }} {{ row.item.owner_lastname  }}</router-link><br />
              <strong>Email : </strong> 
              <template v-if="row.item.emails">
                <div  v-for="oemail in row.item.emails" :key="'email-'+oemail.id">
                  <a :href="'mailto:'+oemail.email">{{ oemail.email }} </a>
                </div>
            
              </template>
            </b-col>
            <b-col
              md="3"
              class="mb-1"
            >
              <strong>Phone : </strong>
              <template v-if="row.item.phones">
                <div v-for="ophone in row.item.phones" :key="'phone-'+ophone.id" >
                <a :href="'tel:'+ophone.phone_no">{{ ophone.phone_no }} </a>
                </div>
              </template>
            </b-col>
            
          </b-row>

          <b-button
            size="sm"
            variant="outline-secondary"
            @click="row.toggleDetails"
          >
            Hide Details
          </b-button>
        </b-card>
      </template>
      <template #cell(actions)="data">
        <span>
        <b-dropdown
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template v-slot:button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="text-body align-middle mr-25"
            />
          </template>
          <b-dropdown-item  :to="{ name: 'property-edit', params: { id: data.item.id } }">
            <feather-icon
              icon="Edit2Icon"
              class="mr-50"
            />
            <span>Edit</span>
          </b-dropdown-item>
          <b-dropdown-item  @click="deleteconfirm(data.item.id)"> 
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
            />
            <span>Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </span>
         
      </template>
      
    </b-table>

    
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalProperty"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
    <b-sidebar
        id="sidebar-right"
        bg-variant="white"
        v-model="isSidebarcommentOpen"
        no-header
        right
        backdrop
        shadow
        ref="formOwner"
        class="side-bar-responsive"
       
        >
         <template >
             <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          
                <h5
                  
                  class="mb-0"
                >
                 Comment
                </h5>
                <div>
                
                  <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="formcomment_close"
                  />
                </div>
                
          </div>
          
          <comments :id="propety_id" v-if="isSidebarcommentOpen" v-on:childToClose="onSubmitClose"  />
        
           
         </template>      
       </b-sidebar>
      
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BMedia, BAvatar, BLink,BFormDatepicker,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BImg,VBToggle,BSidebar,BButtonToolbar, BButtonGroup
} from 'bootstrap-vue'
import { useClipboard } from '@vueuse/core'
import Ripple from 'vue-ripple-directive'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import usePropertyListOwner from './usePropertyListOwner'
import Comments from '../../components/comments/Comments.vue'
import propertyStoreModule from '../propertiesStoreModule'
import { filterTags, formatDateToMonthShort } from '@core/utils/filter'
import axios from '@axios'
import router from '@/router'


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BImg,
    BButtonToolbar, BButtonGroup,
    Comments,
    vSelect,
    ToastificationContent,VBToggle,BSidebar,
    BFormDatepicker,
    BCardCode
  },
  filters: {
    getcomma: function(text){
      if(!text){
        return ''

      }
			 var nStr = text;
			nStr += '';
			var x = nStr.split('.');
			var x1 = x[0];
			var x2 = x.length > 1 ? '.' + x[1] : '';
			var rgx = /(\d+)(\d{3})/;
			while (rgx.test(x1)) {
				x1 = x1.replace(rgx, '$1' + ',' + '$2');
			}
			return x1 ;
		},
  },
  data(){
    return{
      actions_a :[],
      ownerId:router.currentRoute.params.id,
      propety_id:'',
      templates:[],
      isSidebarcommentOpen:false,
      userData: JSON.parse(localStorage.getItem('userData')),
      statuses:[
          { id: 1, name: 'Available now' }, 
          { id: 2, name: 'Rented' }, 
          { id: 3, name: 'Sale with tenant' },
          { id: 5, name: 'Own Stay' },
          { id: 6, name: 'Not Sure' },
          { id: 7, name: 'Cannot contact' },
          { id: 8, name: 'Follow up' },
          { id: 4, name: 'Sold' }
      
         
      
      ],
      
    }
  },
 
  setup() {
      const toast = useToast()
     const { copy } = useClipboard()
     
     const copyPcode = Pcode => {
      
      copy('https://findbangkokroom.com/single-room/'+Pcode)
     console.log('Pcode',Pcode);
      toast({
        component: ToastificationContent,
        props: {
          title: 'url property copied',
          icon: 'CopyIcon',
          variant: 'success',
        },
      })
    }
    const PROPERTY_APP_STORE_MODULE_NAME = 'app-property'

    // Register module
    if (!store.hasModule(PROPERTY_APP_STORE_MODULE_NAME)) store.registerModule(PROPERTY_APP_STORE_MODULE_NAME, propertyStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      
      if (store.hasModule(PROPERTY_APP_STORE_MODULE_NAME)) store.unregisterModule(PROPERTY_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      'Unknow',
      'Sale & Rent',
      'Sale',
      'Rent',
    ]
    
         
  

    const {
      fetchInvoices,
      fetchProperties,
      actions,
      tableColumns,
      tableColumns_property,
      perPage,
      currentPage,
      totalProperty,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPropertyListTable,
      statusId,
      statusFilter,
      propertyfilter,
      unit,
      floor,
      type,
      building,
      bedroom,
      sqm,
      price_rent,
      price_sale,
      ownerfilter,
      fetchTemplates,
      refetchData,
      deleteData,
      clearData,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      updateData
   
    } = usePropertyListOwner()



    return {
      fetchInvoices,
      fetchProperties,
      fetchTemplates,
      copyPcode,
      actions,
      tableColumns,
      tableColumns_property,
      perPage,
      currentPage,
      totalProperty,
      dataMeta,
      perPageOptions,
      statusId,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPropertyListTable,
      ownerfilter,
      clearData,
      statusFilter,
      propertyfilter,
      unit,
      floor,
       type,
      building,
      bedroom,
      sqm,
      price_rent,
      price_sale,
      refetchData,
      deleteData,
      statusOptions,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      filterTags,
      updateData
     
    }
  },
  directives: {
    Ripple,
  },
  created(){
    this.get_a();
    this.getTemplates();
  },
   methods:{
   
     async getTemplates(){
      let templates =   await axios.get('/property/v1/get_templates/'+this.userData.id);
      if(templates.data.data){
        this.templates = templates.data.data;

      }
      console.log('templates',templates);

     },
     async updateDate(property_id,avl_date, status_id,sale_price,rent_price,sqm,expire_date){
      let salePriceSqm = sale_price?sale_price/sqm:'';
      let rentPriceSqm = rent_price?rent_price/sqm:'';
      
     
      let update = {
        id:property_id,
        avaliable_date:avl_date,
        status_id:status_id,
        salePrice:sale_price,
        rentPrice:rent_price,
        salePriceSqm:salePriceSqm?salePriceSqm:'',
        rentPriceSqm:rentPriceSqm?rentPriceSqm:'',
        expire_date:expire_date

      }
      this.updateData(update);
     
     
      

     },
     saveSearch(){
       this.$swal({
      title: 'Save For Template',
      input: 'text',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Save',
      showLoaderOnConfirm: true,
      preConfirm(login) {
       
        if (!login) return null
        
      },
    }).then(result => {
      console.log('result',result);
     
      if(result.isConfirmed && result.value){
        let filter = {
           
            status_id:this.statusId===false?'':this.statusId,
            keyword:this.searchQuery,
           
           
        }
        filter.created_by = this.userData.id;
        filter.title = result.value;
        let data = JSON.stringify(filter);
        axios.post('/property/v1/add_template',data).then(response=>{
            this.getTemplates();
            this.$toast({
            component: ToastificationContent,
              props: {
                title: 'Done!',
                icon: 'BellIcon',
              },
          })
          
        }).catch(()=>{
           this.$toast({
            component: ToastificationContent,
              props: {
                title: 'Error!',
                icon: 'BellIcon',
              },
          })
        });

         console.log('filter',filter);

      }
      
      /*if (result.value) {
        this.$swal({
          title: `${result.value.login}'s avatar`,
          imageUrl: result.value.avatar_url,
          customClass: { confirmButton: 'btn btn-primary' },
        })
      }*/
    })
     },
     
      doCopy(Pname,Pcode) {
      this.$copyText('https://findbangkokroom.com/condo/'+Pname.replace(/\s/g,'-')+'/'+Pcode).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Text copied',
            icon: 'BellIcon',
          },
        })
      }, e => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
          },
        })
      })
    },
    formcomment_show(id){
      this.propety_id = id;
        this.isSidebarcommentOpen = true;
      },
      formcomment_close(){
        this.isSidebarcommentOpen = false;
      },
      onSubmitClose(){  
        this.isSidebarcommentOpen = false;
        this.refetchData();
      },
    async get_a(){
      const actions = await axios.get('/property-action/v1/list');
       this.actions_a = actions.data.data;

    },
    copyTestingCode () {
        let testingCodeToCopy = document.querySelector('#testing-code')
        testingCodeToCopy.setAttribute('type', 'text')    // 不是 hidden 才能複製
        testingCodeToCopy.select()

        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          alert('Testing code was copied ' + msg);
        } catch (err) {
          alert('Oops, unable to copy');
        }

        /* unselect the range */
        testingCodeToCopy.setAttribute('type', 'hidden')
        window.getSelection().removeAllRanges()
      },
      deleteTemplate(id){
         this.$bvModal
          .msgBoxConfirm('Please confirm that you want to delete Template?', {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value === true){
               axios
            .delete('/property/v1/delete_template/'+id)
            .then(response => {
              this.getTemplates();
               this.$toast({
                  component: ToastificationContent,
                    props: {
                      title: 'Done!',
                      icon: 'BellIcon',
                    },
                })
              
            })
            .catch(error => {

            })
              //this.deleteData(id)
            }
            
            //this.deleteData(id)
          })
      },
    deleteconfirm(id){
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Property ID.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value === true){
            this.deleteData(id)
          }
          console.log('value',value);
          //this.deleteData(id)
        })
      
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

